import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
    return (
        <main className="mt-10 text bg-[#333333]">
            <h1 className="flex justify-center font-extrabold text-5xl">Privacy Policy</h1>
            <h2 className="mt-5 flex justify-center font-extrabold text-2xl">Last Changes: august 21 2024 :</h2>
            <div className="mt-5 ml-20 mr-20 content">
                <p className="mt-5 flex justify-center">
                    We receive, collect and store any information you enter on our website or provide to us in any<br/> 
                    other way. In addition, we collect the Internet protocol (IP) address used to connect your<br/> 
                    computer to the Internet; email address and computer and connection information. We may use<br/> 
                    software tools to measure and collect session information, including page response times,<br/> 
                    length of visits to certain pages, page interaction information, and methods used to browse away<br/> 
                    from the page. We also collect personally identifiable information (including email address,).
                </p>
                <p className="mt-5 flex justify-center">
                    When you contact us on our website, as part of the process, we collect the personal information<br/> 
                    you give us such as your email address. Your personal information will only be used for the<br/> 
                    specific reasons mentioned above.
                </p>
                <p className="mt-5 flex justify-center">
                    We collect this non-personal and personal information for the following purposes:
                </p>
                <p className="mt-5 flex justify-center">
                    1.	To be able to contact our visitors and users with general or personalized service-related<br/> 
                    notices and promotional messages;<br/>
                    2.	To create aggregated statistical data and other aggregated and/or inferred non-personal<br/> 
                    information, which we or our business partners may use to provide and improve our<br/> 
                    respective services;<br/>
                    3.	Comply with applicable laws and regulations.
                </p>
                <p className="mt-5 flex justify-center">
                    Our business is hosted on the Hostinguer.com platform.Hostinguer.com provides us with the<br/> 
                    online platform. Your data may be stored through Hostinguer.com's data storage, databases and<br/> 
                    general Hostinguer.com applications.
                </p>
                <p className="mt-5 flex justify-center">
                    We may contact you to notify you regarding your account, to troubleshoot problems with your<br/> 
                    account, to poll your opinions through surveys or questionnaires, to send updates about our<br/> 
                    company, or as otherwise necessary to contact you to enforce our User Agreement, applicable<br/> 
                    national laws, and any agreement we may have with you. For these purposes, we may contact<br/> 
                    you via email.
                </p>
                <p className="email mt-5">
                    If you no longer wish us to process your data, please contact us at&nbsp;<a href="mailto:contact@studiofiregames.fr">contact@studiofiregames.fr</a>
                </p>
                <p className="mt-5 flex justify-center">
                    We reserve the right to modify this privacy policy at any time, so please review it frequently.<br/> 
                    Changes and clarifications will take effect immediately upon their posting on the website. If we<br/> 
                    make material changes to this policy, we will notify you here that it has been updated, so that you<br/> 
                    are aware of what information we collect, how we use it, and under what circumstances, if any,<br/> 
                    we use and/or disclose it.
                </p>
            </div>
        </main>
    )
}